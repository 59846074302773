import React, {useContext, useEffect, useRef } from "react"
import { useFormikContext } from "formik"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faUserCircle, faListAlt } from "@fortawesome/free-solid-svg-icons"

import { AppContext } from "../../context/AppContext"

import FormInput from "../Elements/Form/FormInput"
import Dropzone from "../Upload/Dropzone"
import { isObjectEmpty } from "../../services/general"

import handleScrollToError from "../Elements/Form/utils/handleScrollToError"

import ImageCard from "./EmployeeDetails/ImageCard"

const EmployeeDetails = ({ setFieldValue, errors, submitCount }) => {
  const { state } = useContext(AppContext)
  const { employeeId } = state

  const MAX_FILE_SIZE_IN_BYTES = 3145728
  const MAX_FILE_COUNT = 1

  const formik = useFormikContext()
  const sectionRef = useRef(null)

  const handleScrollCallback = () => {
    sectionRef.current.scrollIntoView({ block: "center" })
  }

  useEffect(() => {
    handleScrollToError({
      formikContext: formik,
      fieldName: "employeeId",
      callback: handleScrollCallback,
    })
  }, [formik.submitCount, formik.isValid])

  useEffect(() => {
    setFieldValue("employeeId", employeeId)
  }, [employeeId])

  return (
    <section ref={sectionRef}>
      <FormInput
        name="employeeIDNumber"
        label="Employee ID Number"
        type = "number"
        maxLength = {7}
        placeholder="5461512"
        isRequired
      />
      <div className="columns">
        <div className="column">
          {!isObjectEmpty(employeeId?.front) ? (
            <ImageCard image={employeeId?.front} type="FRONT" />
          ) : (
            <Dropzone
              maxByteSize={MAX_FILE_SIZE_IN_BYTES}
              maxFileCount={MAX_FILE_COUNT}
              docType="EMPLOYEE_FRONT"
            >
              <FontAwesomeIcon icon={faUserCircle} className="mb-1" size="2x" />
              <br />
              <small>Upload Employee ID front</small>
            </Dropzone>
          )}
          {submitCount > 0 && errors?.employeeId?.front?.path && (
            <p className="help has-text-danger">
              {errors?.employeeId?.front?.path}
            </p>
          )}
        </div>
        <div className="column">
          {!isObjectEmpty(employeeId?.back) ? (
            <ImageCard image={employeeId?.back} type="BACK" />
          ) : (
            <Dropzone
              maxByteSize={MAX_FILE_SIZE_IN_BYTES}
              maxFileCount={MAX_FILE_COUNT}
              docType="EMPLOYEE_BACK"
            >
              <FontAwesomeIcon icon={faListAlt} className="mb-1" size="2x" />
              <br />
              <small>Upload Employee ID back</small>
            </Dropzone>
          )}
          {submitCount > 0 && errors?.employeeId?.back?.path && (
            <p className="help has-text-danger">
              {errors?.employeeId?.back?.path}
            </p>
          )}
        </div>
      </div>
    </section>
  )
}

export default EmployeeDetails
