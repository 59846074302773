import React, { useContext, Fragment, useEffect } from "react"
import { Formik, Form } from "formik"
import Container from "../Layout/Container"
import FormCheckbox from "elements/Form/FormCheckbox"
import Section from "elements/Section"
import ActionButtons from "elements/ActionButtons"
import { AppContext } from "../../context/AppContext"
import EmployeeDetails from "../PersonalDetails/EmployeeDetails"

import MechanicsTable from "./utils/MechanicsTable"
// import RequiredBeneficiaryDocuments from "./RequiredBeneficiaryDocuments"

import { mechanicsValidationSchema } from "./utils/formData"
import mechanics from "./utils/mechanics.json"
import { navigate } from "gatsby"

let agreeToMechanicsBlurb =
  "I have read and I agree to the mechanics of the program."

const Mechanics = ({ flow, nextRoute }) => {
  const { state, dispatch } = useContext(AppContext)
  // const [isValid,setIsValid] = useState(true)

  const handleOnSubmit = async (values) => {
    await dispatch({
      type: "SAVE_CONTEXT_TO_SESSION",
      payload: {
        ...state,
        epharmacy: { ...state.epharmacy, ...values },
      },
    })
    // await dispatch({
    //   type: "SAVE_AGREE_MECHANICS",
    //   payload: values.agreeToMechanics,
    // })
    // await dispatch({
    //   type: "SAVE_ORDERING_FOR",
    //   payload: values.orderingFor,
    // })
    navigate(nextRoute)
  }

  useEffect(() => {
    dispatch({ type: "GET_CONTEXT_FROM_SESSION" })
  }, [])

  useEffect(() => {
    dispatch({ type: "GET_CONTEXT_FROM_SESSION" })
  }, [])

  return (
    <Container isCentered desktop={6}>
      <div className="content">
        <Formik
          initialValues={{
            orderingFor: state.orderingFor,
            agreeToMechanics: state[flow].agreeToMechanics,
            employeeIDNumber: state.epharmacy.employeeIDNumber,
          }}
          onSubmit={handleOnSubmit}
          validationSchema={mechanicsValidationSchema}
        >
          {({ values, submitCount, setFieldValue, errors, isValid }) => (
            <Form>
              <Section title="Program Mechanics">
                <ul>
                  {mechanics[0][flow].map((mechanics, index) => (
                    <Fragment>
                      <li
                        key={index}
                        dangerouslySetInnerHTML={{ __html: mechanics }}
                      />
                    </Fragment>
                  ))}
                </ul>
                <FormCheckbox
                  name="agreeToMechanics"
                  values={values.agreeToMechanics}
                  options={[agreeToMechanicsBlurb]}
                  isRequired
                />
              </Section>
              <Section title="Employee Details">
                <EmployeeDetails
                  submitCount={submitCount}
                  setFieldValue={setFieldValue}
                  errors={errors}
                />
              </Section>

              <ActionButtons
                submit={{
                  label: "Get Started",
                  disabled: !values.agreeToMechanics.length || !isValid,
                  link: nextRoute,
                }}
              />
            </Form>
          )}
        </Formik>
      </div>
    </Container>
  )
}

export default Mechanics
